:root {
  --txt-color: white;
  --btn-outline:  #ff9a24;
  --link-color: white;
  --link-hover: #FE314A;
  --font-display: "Oswald";
  --font-main: "Open Sans";
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="text"],
input[type="email"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  line-height: 1;
  min-height: var(--viewport-height);
  min-width: 320px;
  overflow-x: hidden;
  word-wrap: break-word;
}

body:before {
  content: "";
  display: block;
  background-attachment: scroll;
  height: var(--background-height);
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  width: 100vw;
  z-index: 0;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cfilter%20id%3D%22noise%22%3E%20%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%20%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220.1171875%200%200%200%200%200%200.125%200%200%200%200%200%200.140625%200%200%200%200%200%200.38671875%200%22%20%2F%3E%20%3C%2Ffilter%3E%20%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E"),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(25, 24, 24, 0.478) 0%,
      rgba(20, 19, 19, 0.549) 100%
    ),
    url("/images/endless-adventure-background.jpg");
  background-size: 512px, cover, cover;
  background-position: center, 0% 0%, center;
  background-repeat: repeat, repeat, no-repeat;
  background-color: #e6e6e6;
}

body:after {
  background-color: #212121;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  transition: opacity 1s ease-in-out 1s, visibility 1s 1s;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

body.is-loading:after {
  opacity: 1;
  visibility: visible;
}

:root {
  --background-height: 100vh;
  --site-language-alignment: left;
  --site-language-direction: ltr;
  --site-language-flex-alignment: flex-start;
  --site-language-indent-left: 1;
  --site-language-indent-right: 0;
  --viewport-height: 100vh;
}

html {
  font-size: 16pt;
}

u {
  text-decoration: underline;
}

strong {
  color: inherit;
  font-weight: bolder;
}

em {
  font-style: italic;
}

code {
  background-color: rgba(144, 144, 144, 0.25);
  border-radius: 0.25em;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 0.9em;
  font-weight: normal;
  letter-spacing: 0;
  margin: 0 0.25em;
  padding: 0.25em 0.5em;
  text-indent: 0;
}

mark {
  background-color: rgba(144, 144, 144, 0.25);
}

spoiler-text {
  -webkit-text-stroke: 0;
  background-color: rgba(32, 32, 32, 0.75);
  text-shadow: none;
  text-stroke: 0;
  color: transparent;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
}

spoiler-text.active {
  color: var(--txt-color);
  cursor: text;
}

s {
  text-decoration: line-through;
}

sub {
  font-size: smaller;
  vertical-align: sub;
}

sup {
  font-size: smaller;
  vertical-align: super;
}

a {
  color: inherit;
  text-decoration: underline;
  transition: color 0.25s ease;
}

a[onclick]:not([href]) {
  cursor: pointer;
}

#wrapper {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--viewport-height);
  overflow: hidden;
  position: relative;
  z-index: 2;
}

#main {
  --alignment: center;
  --flex-alignment: center;
  --indent-left: 1;
  --indent-right: 1;
  --border-radius-tl: 0;
  --border-radius-tr: 0;
  --border-radius-br: 0;
  --border-radius-bl: 0;
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  max-width: 100%;
  position: relative;
  text-align: var(--alignment);
  z-index: 1;
  transition: opacity 0.75s ease-in-out 0s;
}

#main > .inner {
  --padding-horizontal: 6rem;
  --padding-vertical: 6rem;
  --spacing: 2rem;
  --width: 57rem;
  border-radius: var(--border-radius-tl) var(--border-radius-tr)
    var(--border-radius-br) var(--border-radius-bl);
  max-width: 100%;
  position: relative;
  width: var(--width);
  z-index: 1;
  padding: var(--padding-vertical) var(--padding-horizontal);
}

#main > .inner > * {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}

#main > .inner > :first-child {
  margin-top: 0 !important;
}

#main > .inner > :last-child {
  margin-bottom: 0 !important;
}

#main > .inner > .full {
  margin-left: calc(var(--padding-horizontal) * -1);
  max-width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
  width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
}

#main > .inner > .full:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  margin-top: calc(var(--padding-vertical) * -1) !important;
}

#main > .inner > .full:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  margin-bottom: calc(var(--padding-vertical) * -1) !important;
}

#main > .inner > .full.screen {
  border-radius: 0 !important;
  max-width: 100vw;
  position: relative;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  right: auto;
}

body.is-loading #main {
  opacity: 0;
}

body.is-instant #main,
body.is-instant #main > .inner > *,
body.is-instant #main > .inner > section > * {
  transition: none !important;
}

body.is-instant:after {
  display: none !important;
  transition: none !important;
}

h1,
h2,
h3,
p {
  direction: var(--site-language-direction);
  position: relative;
}

h1 span.p,
h2 span.p,
h3 span.p,
p span.p {
  display: block;
  position: relative;
}

h1 span[style],
h2 span[style],
h3 span[style],
p span[style],
h1 strong,
h2 strong,
h3 strong,
p strong,
h1 a,
h2 a,
h3 a,
p a,
h1 code,
h2 code,
h3 code,
p code,
h1 mark,
h2 mark,
h3 mark,
p mark,
h1 spoiler-text,
h2 spoiler-text,
h3 spoiler-text,
p spoiler-text {
  -webkit-text-fill-color: currentcolor;
}

h1.style2,
h2.style2,
h3.style2,
p.style2 {
  text-transform: uppercase;
  color: var(--btn-outline);
  font-family: var(--font-display), sans-serif;
  letter-spacing: 0.35rem;
  width: calc(100% + 0.35rem);
  font-size: 0.875em;
  line-height: 1.75;
  font-weight: 400;
}

h1.style2 mark,
h2.style2 mark,
h3.style2 mark,
p.style2 mark {
  color: var(--txt-color);
  background-color: transparent;
}

h1.style2 a,
h2.style2 a,
h3.style2 a,
p.style2 a {
  color: var(--link-color);
  text-decoration: underline;
}

h1.style2 a:hover,
h2.style2 a:hover,
h3.style2 a:hover,
p.style2 a:hover {
  color: var(--link-hover);
}

h1.style2 span.p:nth-child(n + 2),
h2.style2 span.p:nth-child(n + 2),
h3.style2 span.p:nth-child(n + 2),
p.style2 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}

h1.style3,
h2.style3,
h3.style3,
p.style3 {
  color: var(--txt-color);
  font-family: var(--font-main), sans-serif;
  font-size: 1em;
  line-height: 1.6em;
  font-weight: 300;
}

h1.style3 mark,
h2.style3 mark,
h3.style3 mark,
p.style3 mark {
  background-color: transparent;
}

h1.style3 a:hover,
h2.style3 a:hover,
h3.style3 a:hover,
p.style3 a:hover {
  color: var(--link-hover);
}

h1.style3 a,
h2.style3 a,
h3.style3 a,
p.style3 a {
  text-decoration: underline;
}

h1.style3 span.p:nth-child(n + 2),
h2.style3 span.p:nth-child(n + 2),
h3.style3 span.p:nth-child(n + 2),
p.style3 span.p:nth-child(n + 2) {
  margin-top: 1rem;
}

.image {
  display: block;
  line-height: 0;
  max-width: 100%;
  position: relative;
}

.image .frame {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  vertical-align: top;
  width: 100%;
}

.image .frame img {
  border-radius: 0 !important;
  max-width: 100%;
  vertical-align: top;
  width: inherit;
}

.image.full .frame {
  display: block;
}

.image.full:first-child .frame {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.image.full:last-child .frame {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

#image01 .frame {
  width: 15rem;
  transition: none;
}

#image01 .frame img {
  transition: none;
}

.buttons {
  cursor: default;
  display: flex;
  justify-content: var(--flex-alignment);
  letter-spacing: 0;
  padding: 0;
}

.buttons li {
  max-width: 100%;
}

.buttons li a {
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
}

.buttons.style1 {
  gap: 1.25rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.buttons.style1:not(:first-child) {
  margin-top: 3rem !important;
}

.buttons.style1:not(:last-child) {
  margin-bottom: 3rem !important;
}

.buttons.style1 li a {
  display: inline-flex;
  width: auto;
  height: 4.5rem;
  line-height: 4.5rem;
  padding: 0 2.25rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: var(--font-display), sans-serif;
  letter-spacing: 0.35rem;
  padding-left: calc(0.35rem + 2.25rem);
  font-size: 0.875em;
  font-weight: 400;
  border-radius: 2.5rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  transition: transform 0.375s ease, color 0.375s ease,
    background-color 0.375s ease, border-color 0.375s ease;
}

.buttons.style1 li a svg {
  display: block;
  fill: var(--link-color);
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  min-width: 18px;
  width: 2.03125em;
  margin-left: 1.5rem;
  margin-right: calc(-0.125em + 0.35rem);
  transition: fill 0.375s ease;
}

.buttons.style1 li a .label {
  direction: var(--site-language-direction);
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  width: 100%;
}

.buttons.style1 .button {
  color: #ffffff;
  border: solid 2px var(--btn-outline);
}

.buttons.style1 li a:hover {
  transform: scale(1.06);
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--flex-alignment);
  letter-spacing: 0;
  padding: 0;
}

.icons li {
  position: relative;
  z-index: 1;
}

.icons li a {
  align-items: center;
  display: flex;
  justify-content: center;
}

.icons li a svg {
  display: block;
  position: relative;
}

.icons li a + svg {
  display: block;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.icons li a .label {
  display: none;
}

.icons.style1 {
  font-size: 1.375em;
  gap: 1rem;
}

.icons.style1 li a {
  border-radius: 100%;
  height: 2em;
  width: 2em;
  transition: transform 0.375s ease, color 0.375s ease,
    background-color 0.375s ease, border-color 0.375s ease;
}

.icons.style1 li a svg {
  height: 60%;
  width: 60%;
  transition: fill 0.375s ease;
}

.icons.style1 a svg {
  fill: var(--link-color);
}

.icons.style1 a {
  border: solid 2px transparent;
}

.icons.style1 a:hover {
  border-color: var(--btn-outline) !important;
}

.icons.style1 a:hover svg {
  fill: var(--btn-outline) !important;
}

.icons.style1 li a + svg {
  transition: transform 0.375s ease, fill 0.375s ease, stroke 0.375s ease;
}

.icons.style1 li a:hover {
  transform: scale(1.1125);
}

.icons.style1 li a:hover + svg {
  transform: scale(1.1125);
}

p.links{
  font-size: 0.8em;
}
.links a {
  color: rgba(255, 255, 255, 0.498);
  margin-top: 1.5rem !important;
  text-decoration: none !important;

  /* padding: 0px 20px; */
}
.links a + a {
  border-left: 1px solid rgba(255, 255, 255, 0.498);
}
.links a:hover {
  text-transform: none !important;
  color: rgba(255, 255, 255, 1) !important;
}

@media (max-width: 1920px) {
}

@media (max-width: 1680px) {
  html {
    font-size: 12pt;
  }
}

@media (max-width: 1280px) {
  html {
    font-size: 12pt;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 980px) {
  html {
    font-size: 10pt;
  }
}

@media (max-width: 736px) {
  html {
    font-size: 10pt;
  }

  #main > .inner {
    --padding-horizontal: 2rem;
    --padding-vertical: 3.5rem;
    --spacing: 2rem;
  }

  h1.style2,
  h2.style2,
  h3.style2,
  p.style2 {
    letter-spacing: 0.30625rem;
    width: calc(100% + 0.30625rem);
    font-size: 0.875em;
    line-height: 1.75;
  }

  h1.style3,
  h2.style3,
  h3.style3,
  p.style3 {
    letter-spacing: 0rem;
    width: 100%;
    font-size: 1em;
    line-height: 2;
  }

  #image01 .frame {
    width: 15rem;
  }

  .buttons.style1 {
    gap: 1.25rem;
  }

  .buttons.style1:not(:first-child) {
    margin-top: 2.25rem !important;
  }

  .buttons.style1:not(:last-child) {
    margin-bottom: 2.25rem !important;
  }

  .buttons.style1 li a {
    letter-spacing: 0.35rem;
    padding-left: calc(0.35rem + 2.25rem);
    font-size: 0.875em;
  }

  .buttons.style1 li a svg {
    width: 2.03125em;
  }

  .icons.style1 {
    font-size: 1.375em;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  #main > .inner {
    --spacing: 1.75rem;
  }
}

@media (max-width: 360px) {
  #main > .inner {
    --padding-horizontal: 1.5rem;
    --padding-vertical: 2.625rem;
    --spacing: 1.5rem;
  }

  h1.style2,
  h2.style2,
  h3.style2,
  p.style2 {
    font-size: 0.875em;
  }

  h1.style3,
  h2.style3,
  h3.style3,
  p.style3 {
    font-size: 1em;
  }

  .buttons.style1 {
    gap: 0.9375rem;
  }

  .icons.style1 {
    gap: 0.75rem;
  }
}
