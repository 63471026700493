
.legalsection{
  
  .content{
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    color:white;
    padding: 20px 40px;
    font-family: 'Open Sans', sans-serif
  }

  h1{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 300;
    line-height: 45px;
  }

  h2{
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
  }

  h3{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }

  p{
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
  }

  ul{
    font-size: 16px;
    line-height: 26px;
    list-style-type: lower-roman;
    position: static;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
  }

  .last-updated{
    color: #888;
    font-style: italic;
  }

}
